import React, { useContext } from "react";
import "./About.css";
import { AuthContext } from "../../Context/Context";
import { Link } from "react-scroll";

const About = ({ dataCat }) => {
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  return (
    <section className="about">
      <div className="section_container">
        <div
          style={{
            gap: i18n.language === "ar" ? "0 20px" : "",
          }}
          className={`section_header ${i18n.language === "ar" ? "ar" : ""}`}
        >
          <h2
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("menu.About")}
          </h2>
        </div>
        <div
          className="section_subheader"
          style={{
            letterSpacing: i18n.language === "ar" ? "0" : "",
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            direction: i18n.language === "ar" ? "rtl" : "",
            fontSize: i18n.language === "ar" ? "14px" : "",
          }}
        >
          " {t("about_header")} "
        </div>
        <div
          className="about_body"
          style={{
            direction: i18n.language === "ar" ? "row-reverse" : "",
          }}
        >
          <div
            className="about_body_l"
            style={{
              direction: i18n.language === "ar" ? "rtl" : "",
            }}
          >
            <img src={require("../../assets/image/Ragheed.jpeg")} />
          </div>
          <div className="about_body_r">
            <p
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
                fontSize: i18n.language === "ar" ? "20px" : "",
                direction: i18n.language === "ar" ? "rtl" : "",
              }}
            >
              {i18n.language === "ar" ? dataCat?.bio_ar : dataCat?.bio}
            </p>
            <div
              className="home_btn"
              style={{
                position: "relative",
                zIndex: "10",
                direction: i18n.language === "ar" ? "rtl" : "",
              }}
            >
              <button className="btn-donate">
                <Link
                  to="MyBusiness"
                  onClick={() => setShowSide(!showSide)}
                  className={`nav-link`}
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={800}
                  style={{
                    fontFamily: i18n.language === "ar" ? "Cairo" : "",
                  }}
                >
                  {t("home_b_r.Read_more")}
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
