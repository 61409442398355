import React, { useContext } from "react";
import "./Home.css";
// import { Link } from "react-scroll";
import { AuthContext } from "../../Context/Context";
import { Link } from "react-scroll";

const Home = () => {
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  // const items = [
  //   {
  //     image: require("../../assets/image/ph-ya-1.png"),
  //     h2: "ECKL2007 Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-2.png"),
  //     h2: "ELAB20018 Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-3.png"),
  //     h2: "Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-4.png"),
  //     h2: "Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-5.png"),
  //     h2: "Tools",
  //   },
  //   {
  //     image: require("../../assets/image/ph-ya-6.png"),
  //     h2: "Tools",
  //   },
  // ];
  // const icons = [
  //   {
  //     icon: require("../../assets/image/icon-yy1.png"),
  //     title: "Test",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy2.png"),
  //     title: "we",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy3.png"),
  //     title: "3",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy4.png"),
  //     title: "4",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy5.png"),
  //     title: "5",
  //   },
  //   {
  //     icon: require("../../assets/image/icon-yy6.png"),
  //     title: "6",
  //   },
  // ];
  return (
    <section className="home">
      <div className="me">
        <img src={require("../../assets/image/IMG_8436-copy2.png")} alt="" />
      </div>
      <div
        className="home_body"
        style={{
          direction: i18n.language === "ar" ? "rtl" : "",
        }}
      >
        <h1
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("home_b_r.h1")}
        </h1>
        <p
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
            fontSize: i18n.language === "ar" ? "16px" : "",
          }}
        >
          {t("home_b_r.p")}
        </p>
        <div className="home_btn">
          <button className="btn-donate">
            <Link
              to="about"
              onClick={() => setShowSide(!showSide)}
              className={`nav-link`}
              spy={true}
              smooth={true}
              offset={-60}
              duration={800}
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
              }}
            >
              {t("Bus_Vis_Btn")}
            </Link>
          </button>
        </div>
      </div>
      <div className="home_footer">
        <Link
          to="about"
          onClick={() => setShowSide("about")}
          className={`${showSide === "about" ? "active" : ""} nav-link`}
          spy={true}
          smooth={true}
          offset={-60}
          duration={800}
        >
          Scroll Down
        </Link>
      </div>
    </section>
  );
};

export default Home;
