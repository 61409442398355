import React, { useContext } from "react";
import "./Footer.css";
import { AuthContext } from "../../Context/Context";

const Footer = () => {
  const { t, i18n } = useContext(AuthContext);
  return (
    <div
      className="footer section_container"
      style={{
        fontFamily: i18n.language === "ar" ? "Cairo" : "",
        direction: i18n.language === "ar" ? "rtl" : "",
        letterSpacing: i18n.language === "ar" ? "0" : "",
        fontSize: i18n.language === "ar" ? "26px" : "",
      }}
    >
      {i18n.language === "ar"
        ? "جميع الحقوق محفوظة لرغيد 2024 ©"
        : `Copyright © 2024 RAGHEED. All rights reserved.`}
    </div>
  );
};

export default Footer;
