import React, { useContext, useEffect, useState } from "react";
import "./MyBusiness.css";
import axios from "axios";
import { API } from "../../Config/Api";
import { Paginator } from "primereact/paginator";
import { AuthContext } from "../../Context/Context";

const MyBusiness = () => {
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [wait, setWait] = useState(false);
  const pageSize = 3;
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");
  const onPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setFirst(event.first);
    setRows(event.rows);
    setWait(true);
    axios
      .get(
        `${
          API.Project.client_project_get
        }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setData(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.totalItems);
        setWait(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    setWait(true);
    axios
      .get(
        `${
          API.Project.client_project_get
        }?page=${currentPage}&size=${pageSize}&isPaginate=${1}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setData(res?.data?.data?.data);
        setTotalItems(res?.data?.data?.totalItems);
        setWait(false);
        // setDataCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);
  return (
    <section className="MyBusiness">
      <div className="section_container">
        <div className={`section_header`}>
          <h2
            style={{
              fontFamily: i18n.language === "ar" ? "Cairo" : "",
            }}
          >
            {t("menu.MyBusiness")}
          </h2>
        </div>
        <div className="MyBusiness_body">
          {wait ? (
            <div
              style={{
                height: "460px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span className="loader"></span>
            </div>
          ) : (
            data.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`MyBusiness_b ${
                    index % 2 === 0 ? "MyBusiness_b_l" : "MyBusiness_b_r"
                  }`}
                >
                  <div className="img">
                    <a href={item.project_link} target="_blank">
                      <img
                        src={`${API.Admin.Image}/${item.image_path}`}
                        alt=""
                      />
                    </a>
                  </div>
                  <a
                    className="content"
                    href={item.project_link}
                    target="_blank"
                  >
                    <div className="">
                      <h2
                        style={{
                          fontFamily: i18n.language === "ar" ? "Cairo" : "",
                          direction: i18n.language === "ar" ? "rtl" : "",
                          letterSpacing: i18n.language === "ar" ? "0" : "",
                          fontSize: i18n.language === "ar" ? "35px" : "",
                        }}
                      >
                        {i18n.language === "ar" ? item.title_ar : item.title}
                      </h2>
                      <p
                        style={{
                          fontFamily: i18n.language === "ar" ? "Cairo" : "",
                          direction: i18n.language === "ar" ? "rtl" : "",
                          fontSize: i18n.language === "ar" ? "16px" : "",
                        }}
                      >
                        {i18n.language === "ar"
                          ? item.description_ar
                          : item.description}
                      </p>
                    </div>
                  </a>
                </div>
              );
            })
          )}
          {data.length === 0 && (
            <div
              style={{
                height: "460px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "50px",
                color: "var(--Pr-color)",
              }}
            >
              No Projects
            </div>
          )}
          <div className="card">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalItems}
              // rowsPerPageOptions={[10, 20, 30]}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyBusiness;
