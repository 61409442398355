import React, { useContext, useEffect, useState } from "react";
import Header from "./Component/Header/Header";
import Home from "./Page/Home/Home";
import Service from "./Page/Service/Service";
import Contact from "./Page/Contact/Contact";
import Footer from "./Page/Footer/Footer";
import About from "./Page/About/About";
import MyBusiness from "./Page/MyBusiness/MyBusiness";
import axios from "axios";
import { API } from "./Config/Api";
import { AuthContext } from "./Context/Context";
import { Link } from "react-scroll";
import video1 from "./assets/video/1009.mp4";

const Layout = () => {
  const [dataCat, setDataCat] = useState();
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(`${API.About.client_about_get}`, {
        headers: {
          "Accept-Language": "en",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setDataCat(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Header />
      <Home />
      <div className="first_Sec">
        <p
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
          }}
          data-text={`${
            i18n.language === "ar"
              ? "تواصل معنا اليوم ودعنا ننمي عملك معًا!"
              : "Get in touch with us today and let's grow your business together!"
          }`}
        >
          {i18n.language === "ar"
            ? "تواصل معنا اليوم ودعنا ننمي عملك معًا!"
            : "Get in touch with us today and let's grow your business together!"}
        </p>
        <div className="home_btn">
          <button className="btn-donate">
            <Link
              to="MyBusiness"
              onClick={() => setShowSide(!showSide)}
              className={`nav-link`}
              spy={true}
              smooth={true}
              offset={-60}
              duration={800}
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
              }}
            >
              {t("Bus_Vis_Btn")}
            </Link>
          </button>
        </div>
      </div>
      <About dataCat={dataCat} />
      <div className="Secs_Sec">
        <p
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
          }}
          data-text={`${
            i18n.language === "ar"
              ? "اتصل بنا الآن ولنبدأ في بناء مستقبل أكثر إشراقًا لعملك!"
              : "Contact us now and let's start building a brighter future for your business!"
          }`}
        >
          {i18n.language === "ar"
            ? "اتصل بنا الآن ولنبدأ في بناء مستقبل أكثر إشراقًا لعملك!"
            : "Contact us now and let's start building a brighter future for your business!"}
        </p>
        <div className="home_btn">
          <button className="btn-donate">
            <Link
              to="MyBusiness"
              onClick={() => setShowSide(!showSide)}
              className={`nav-link`}
              spy={true}
              smooth={true}
              offset={-60}
              duration={800}
              style={{
                fontFamily: i18n.language === "ar" ? "Cairo" : "",
              }}
            >
              {t("Bus_Vis_Btn")}
            </Link>
          </button>
        </div>
      </div>
      <MyBusiness />
      <div className="video-responsive">
        <h1
          style={{
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
          }}
        >
          {t("TributeVideo.TributeVideo_h1")}
        </h1>
        <p
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
            fontFamily: i18n.language === "ar" ? "Cairo" : "",
            letterSpacing: i18n.language === "ar" ? "0" : "",
          }}
        >
          {t("TributeVideo.TributeVideo_p")}
        </p>
        <video width="50%" controls>
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Contact dataCat={dataCat} />
      <Footer />
    </>
  );
};

export default Layout;
