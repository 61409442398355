const BASIC_URL = "https://it.ragheedd.mr-appss.com/api";

// const BASIC_URL = "http://192.168.0.201:8000/api";
const URL_Image = "https://it.ragheedd.mr-appss.com/";
// const URL_Image = "http://192.168.0.201:8000";

const Config = {
  api: {
    Admin: {
      Login: `${BASIC_URL}/v1/login`,
      logout: `${BASIC_URL}/v1/cms/logout`,
      Password_change: `${BASIC_URL}/v1/cms/password/change`,
      profile_update: `${BASIC_URL}/v1/cms/profile/update`,
      v1_profile: `${BASIC_URL}/v1/profile`,
      profile: `${BASIC_URL}/v1/profile`,
      Image: `${URL_Image}`,
    },
    Notifications: {
      v1_notifications: `${BASIC_URL}/v1/notifications`,
    },
    Users: {
      cms_admins: `${BASIC_URL}/v1/cms/admins`,
      v1_cms_users: `${BASIC_URL}/v1/cms/users`,
      v1_cms_users_block: `${BASIC_URL}/v1/cms/users/block`,
    },
    About: {
      client_about_get: `${BASIC_URL}/client/about/get`,
    },
    Contact: {
      client_contact_add: `${BASIC_URL}/client/contact/add`,
    },
    Project: {
      client_project_get: `${BASIC_URL}/client/project/get`,
    },
  },
};
export const API = Config.api;
