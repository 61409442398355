import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-scroll";
import "./Header.css";
import { AuthContext } from "../../Context/Context";
import { ToggleButton } from "primereact/togglebutton";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t, i18n, showSide, setShowSide } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`nav ${scrollPosition > 5 ? "scrollClassName" : ""}`}>
      <nav
        style={{
          direction: i18n.language === "ar" ? "rtl" : "",
        }}
      >
        <div
          className="logo"
          style={{
            direction: i18n.language === "ar" ? "rtl" : "",
          }}
        >
          {/* <h1>Portfolio</h1> */}
          <div className="image">
            <img
              src={require("../../assets/image/Untitled-1LOGO-copy.png")}
              alt=""
            />
          </div>
          <i
            className="bx bx-menu-alt-right"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          ></i>
        </div>
        <div className={`menu ${showMenu ? "active" : ""}`}>
          <ul
            style={{
              direction: i18n.language === "ar" ? "rtl" : "",
            }}
          >
            <li>
              <Link
                to="home"
                onClick={() => {
                  setShowSide(!showSide);
                  setShowMenu(!showMenu);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={0}
                duration={800}
              >
                {t("menu.home")}
              </Link>
            </li>
            <li>
              <Link
                to="about"
                onClick={() => {
                  setShowMenu(!showMenu);
                  setShowSide(!showSide);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                {t("menu.About")}
              </Link>
            </li>
            <li>
              <Link
                to="MyBusiness"
                onClick={() => {
                  setShowMenu(!showMenu);
                  setShowSide(!showSide);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                {t("menu.MyBusiness")}
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                onClick={() => {
                  setShowMenu(!showMenu);
                  setShowSide(!showSide);
                }}
                className={`nav-link ${i18n.language === "ar" ? "arLang" : ""}`}
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
              >
                {t("menu.contact")}
              </Link>
            </li>
            <ToggleButton
              onLabel="AR"
              offLabel="EN"
              onIcon="pi pi-globe"
              offIcon="pi pi-globe"
              checked={checked}
              // onChange={(e) => setChecked(e.value)}
              onChange={(e) => {
                if (i18n.language === "en" && e.value === true) {
                  i18n.changeLanguage("ar");
                  setChecked(e.value);
                } else {
                  i18n.changeLanguage("en");
                  setChecked(e.value);
                }
              }}
            />
            {/* <select
              onChange={() => {
                if (i18n.language === "en") {
                  i18n.changeLanguage("ar");
                } else {
                  i18n.changeLanguage("en");
                }
              }}
            >
              <option value="en">en</option>
              <option value="ar">ar</option>
            </select> */}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
